import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { ToastError, ToastSuccess } from "../../utils/helpers";
import axios from "axios";
import { Loading } from "../../components/lottie/loader/Loading";
import { baseUrl } from "../../utils/api";

const Login = () => {
  let history = useHistory();
  const [user, setUser] = useState({
    email: "",
    password: "",
    returnSecureToken: true,
  });
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (e) => {
    let required = ["email", "password"];
    try {
      e.preventDefault();
      let failed;
      for (let req of required) {
        if (user[req].trim() === "") failed = true;
      }
      if (failed) {
        return ToastError("Please provide both email and password");
      }
      setLoading(true);
      let res = await axios.post(`${baseUrl}/login`, user);
      localStorage.setItem("token", res.data.token);
      localStorage.setItem(
        "expiry_time",
        new Date().getTime() + res.data.expiresIn * 1000
      );
      localStorage.setItem("user", JSON.stringify(res.data.data));

      if (res.data.data.type === "client") {
        setLoading(false);
        return history.push("/dashboard");
      } else {
        ToastError("Invalid Client credentials");

        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      ToastError("Invalid login details");
    }
  };
  return (
    <section className="sign-in-page">
      <div className="container sign-in-page-bg mt-5 p-0">
        <div className="row no-gutters">
          <div className="col-md-6 text-center">
            <div className="sign-in-detail text-white">
               <div className="image-container">
                <img
                  // src="/assets/images/logo.png"
                  className="img-fluid"
                  // alt="logo"
                />
              </div> 
              <OwlCarousel
                className="owl-theme"
                autoplay={true}
                loop={true}
                margin={10}
                nav
                items={1}
              >
                <div class="item">
                  <div className="login-slider">
                    <img
                      src="/assets/images/slider1.png"
                      className="img-fluid mb-4"
                      alt="logo"
                    />
                  </div>
                </div>
                <div class="item">
                  <div className="login-slider">
                    <img
                      src="/assets/images/slider2.jpg"
                      className="img-fluid mb-4"
                      alt="logo"
                    />
                  </div>
                </div>
                {/* <div class="login-slider">
                  <img
                    src="/assets/images/slider3.jpg"
                    className="img-fluid mb-4"
                    alt="logo"
                  />
                </div> */}
              </OwlCarousel>
            </div>
          </div>
          <div className="col-md-6 position-relative">
            <div className="sign-in-from">
              <h1 className="mb-0">Sign in</h1>
              <p>
                Enter your email address and password to access admin panel.
              </p>
              <form className="mt-4" onSubmit={handleSubmit}>
                <div className="form-group">
                  <label for="exampleInputEmail1">Email address</label>
                  <input
                    type="email"
                    className="form-control mb-0"
                    id="exampleInputEmail1"
                    placeholder="Enter email"
                    onChange={(e) =>
                      setUser({
                        ...user,
                        email: e.target.value,
                      })
                    }
                    value={user.email}
                  />
                </div>
                <div className="form-group">
                  <label for="exampleInputPassword1">Password</label>
                  {/* <a href="#" className="float-right">Forgot password?</a> */}
                  <input
                    type="password"
                    className="form-control mb-0"
                    id="exampleInputPassword1"
                    placeholder="Password"
                    onChange={(e) =>
                      setUser({
                        ...user,
                        password: e.target.value,
                      })
                    }
                    value={user.password}
                  />
                </div>
                <div className="d-inline-block w-100">
                  <div className="custom-control custom-checkbox d-inline-block mt-2 pt-1">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="customCheck1"
                    />
                    <label className="custom-control-label" for="customCheck1">
                      Remember Me
                    </label>
                  </div>
                  {!loading ? (
                    <button
                      type="submit"
                      className="btn btn-primary float-right"
                    >
                      Sign in
                    </button>
                  ) : (
                    <div className="pull-right">
                      <Loading />
                    </div>
                  )}
                </div>
                {!loading ? <div className="mb-5"></div> : null}
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Login;
