import AppColors from "../../utils/AppColors";

const styles = {
  content: {
    marginTop: "4rem",
    marginBottom: "4rem",
  },
  text: {
    color: AppColors.gray,
  },
};
export default styles;
