import React from "react";
import styles from "./styles";

const EmptyStateWrapper = () => {
  return (
    <div className="d-flex justify-content-center" style={styles.content}>
      <h3 style={styles.text}>No Record Found</h3>
    </div>
  );
};

export default EmptyStateWrapper;
