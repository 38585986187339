import React from "react";
import InputField from "../InputField";
import {
  ToastError,
  ToastSuccess,
  validationHandler,
} from "../../utils/helpers";
import { useMutation } from "react-query";
import { APIFunctions } from "../../utils/api";
import { RollLoader } from "../lottie/loader/Loading";
import styles from "./styles";
import Button from "../Button";

const ChangePasswordForm = () => {
  const [data, setData] = React.useState({
    current_password: "",
    new_password: "",
  });

  const { mutateAsync, isLoading: loading } = useMutation(
    APIFunctions.change_password
  );

  const onChangeHandler = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const required = ["current_password", "new_password"];
      let res = validationHandler(data, required);
      if (res.failed) return ToastError("Password fields are required");
      await mutateAsync(data);
      ToastSuccess("Password has been changed successfully");
      setData({
        password: "",
        confirm: "",
      });
    } catch (err) {
      ToastError(err.msg);
    }
  };
  return (
    <div className="tab-pane fade  " id="chang-pwd" role="tabpanel">
      <div className="iq-card">
        <div className="iq-card-header d-flex justify-content-between">
          <div className="iq-header-title">
            <h4 className="card-title">Change Password</h4>
          </div>
        </div>
        <div className="iq-card-body">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="cpass">Current Password:</label>
              <InputField
                placeholder={"Enter current password"}
                value={data?.current_password}
                name={"current_password"}
                onChange={onChangeHandler}
                type="password"
              />
            </div>
            <div className="form-group">
              <label htmlFor="npass">Confirm Password:</label>
              <InputField
                placeholder={"Enter new password"}
                value={data?.new_password}
                name={"new_password"}
                onChange={onChangeHandler}
                type="password"
              />
            </div>

            <div style={styles.button_container}>
              {loading ? (
                <RollLoader />
              ) : (
                <Button type={"submit"} text={"Save"} onClick={handleSubmit} />
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ChangePasswordForm;
