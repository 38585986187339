import React, { useCallback } from "react";
import Map, { Marker, Source, Layer } from "react-map-gl";
import DrawControl from "../draw-control";

const MapBoxComponent = ({ onUpdateHandler, bikes, geometry }) => {
  const onUpdate = useCallback((e) => {
    onUpdateHandler(e?.features?.[0]?.geometry?.coordinates || []);
  }, []);

  const onDelete = useCallback(() => {
    onUpdateHandler([]);
  }, []);
  const polygon = document.getElementsByClassName("mapbox-gl-draw_polygon")[0];
  if (polygon) {
    polygon.innerHTML = " ╂";
  }
  const thrash = document.getElementsByClassName("mapbox-gl-draw_trash")[0];
  if (thrash) {
    thrash.innerHTML = "🗑";
  }

  const geoJSON = {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: geometry,
        },
      },
    ],
  };

  const layerStyle = {
    id: "contours",
    type: "line",
    paint: {
      "line-width": 1,
      "line-color": "#007cbf",
    },
  };

  return (
    <div className="mapouter">
      <Map
        initialViewState={{
          lat: 6.5244,
          lng: 3.3792,
          zoom: 5,
        }}
        style={{
          width: "100%",
          height: "580px",
        }}
        mapStyle="mapbox://styles/mapbox/streets-v9"
        mapboxAccessToken={process.env.REACT_APP_MAP_BOX}
      >
        {bikes?.map((item, i) => (
          <Marker
            latitude={item.current_latitude}
            longitude={item.current_longitude}
            key={i}
          >
            <img
              src={"/assets/images/locator.png"}
              style={{ width: "1.5rem", height: "auto" }}
            />
          </Marker>
        ))}
        <Source id="my-data" type="geojson" data={geoJSON}>
          <Layer {...layerStyle} />
        </Source>
        <DrawControl
          position="top-left"
          displayControlsDefault={false}
          controls={{
            polygon: true,
            trash: true,
          }}
          defaultMode="draw_polygon"
          onCreate={onUpdate}
          onUpdate={onUpdate}
          onDelete={onDelete}
        />
      </Map>
    </div>
  );
};

export default MapBoxComponent;
