import React, { Component } from "react";
import GoogleMapReact from "google-map-react";
import { RollLoader } from "./lottie/loader/Loading";

const AnyReactComponent = ({ item, setLoading, setBike }) => (
  <div
    onClick={async () => {
      setLoading(true);
      setBike(item);
      setLoading(false);
    }}
  >
    <div style={{ height: "30px", width: "30px" }}>
      <img src={"/assets/images/locator.png"} style={{ width: "100%" }} />
    </div>
  </div>
);

export const MapContainer = ({ bikes, setBike, heatmap }) => {
  const [loading, setLoading] = React.useState(false);
  const defaultProps = {
    center: {
      lat: 6.5244,
      lng: 3.3792,
    },
    zoom: 11,
  };
  return (
    // Important! Always set the container height explicitly
    <div style={{ height: "100vh", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        heatmapLibrary={true}
        heatmap={heatmap}
      >
        {bikes &&
          bikes.map((item, i) => (
            <AnyReactComponent
              lat={item.current_latitude}
              lng={item.current_longitude}
              item={item}
              setLoading={setLoading}
              key={i}
              setBike={setBike}
            />
          ))}
      </GoogleMapReact>
      {loading ? <RollLoader /> : null}
    </div>
  );
};
