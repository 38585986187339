import axios from "axios";
import React, { useEffect } from "react";
import { Header } from "../Header";
import { Sidebar } from "../Sidebar";
import PageLoader from "../Loader";
import { useFetchFleetMetric } from "../../utils/queries";

const DefaultLayout = ({ children }) => {
  const { isLoading } = useFetchFleetMetric();
  const [loading, setLoading] = React.useState(true);
  const checkForToken = () => {
    const token = localStorage.getItem("token");
    if (!token) {
      return (window.location.href = "/login");
    }
    setLoading(false);
  };
  useEffect(() => {
    checkForToken();
  }, []);
  return (
    <React.Fragment>
      {isLoading || loading ? (
        <PageLoader />
      ) : (
        <div className="wrapper">
          <Sidebar />
          {/* <!-- Page Content  --> */}
          <div id="content-page" className="content-page">
            <Header />

            {children}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default DefaultLayout;
