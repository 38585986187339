import React, { useEffect, useMemo, useState } from "react";
import { APIFunctions } from "../../../utils/api";
import { ToastError, ToastSuccess, __flatten } from "../../../utils/helpers";
import BusinessInfoForm from "../../../components/BusinessInfoForm";
import { useMutation, useQueryClient } from "react-query";
import { GET_CONTACT } from "../../../utils/query_keys";
import ChangePasswordForm from "../../../components/ChangePasswordForm";
import PageLoader from "../../../components/Loader";
import { CustomSwitch } from "../../../components/components";
import {
  useFetchContact,
  useFetchCountries,
  useFetchCountryStates,
  useFetchIndustries,
  useFetchLcdaAreas,
  useFetchStateLCDAs,
} from "../../../utils/queries";

const Settings = () => {
  const [contact, setContact] = useState({});
  const queryClient = useQueryClient();
  const { mutateAsync: updateClient, isLoading: isUpdating } = useMutation(
    APIFunctions.update_client_info
  );

  const { id } = useMemo(
    () => JSON.parse(localStorage.getItem("user")) || {},
    []
  );

  const { data: contactData, isLoading } = useFetchContact();
  const { data: industries } = useFetchIndustries();

  const { data: countries } = useFetchCountries();

  const { data: states } = useFetchCountryStates(contact?.country_id);

  const { data: lcdas } = useFetchStateLCDAs(contact?.state_id);

  const { data: aop } = useFetchLcdaAreas(contact?.lcda_id);

  const handleUpdateSubmit = async (e) => {
    try {
      e.preventDefault();
      let fd = {
        ...contact,
      };
      delete fd["email"];
      delete fd["phone"];
      await updateClient(fd);
      queryClient.invalidateQueries(GET_CONTACT);
      ToastSuccess("Update has been saved");
    } catch (error) {
      ToastError(error.msg);
    }
  };

  const onChangeHandler = (e) => {
    if (e.target.name === "country_id") {
      return setContact({
        ...contact,
        [e.target.name]: e.target.value,
        state_id: "",
        lcda_id: "",
        area_id: "",
      });
    }
    if (e.target.name === "state_id") {
      return setContact({
        ...contact,
        [e.target.name]: e.target.value,
        lcda_id: "",
        area_id: "",
      });
    }
    if (e.target.name === "lcda_id") {
      return setContact({
        ...contact,
        [e.target.name]: e.target.value,
        area_id: "",
      });
    }
    setContact({ ...contact, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    setContact({
      email: contactData?.data?.email,
      first_name: contactData?.data?.owner?.first_name,
      last_name: contactData?.data?.owner?.last_name,
      phone: contactData?.data?.phone,
      address: contactData?.data?.address,
      type: "client",
      status: contactData?.data?.status,
      area_id: contactData?.data?.area_id,
      lcda_id: contactData?.data?.lcda_id,
      name: contactData?.data?.name,
      country_id: contactData?.data?.country_id,
      state_id: contactData?.data?.state_id,
      industry_id: contactData?.data?.industry_id,
    });
  }, [contactData]);

  return (
    <div className="container-fluid">
      {isLoading ? <PageLoader /> : null}
      <div className="row">
        <div className="col-lg-12">
          <div className="iq-card">
            <div className="iq-card-body p-0">
              <div className="iq-edit-list">
                <ul className="iq-edit-profile d-flex nav nav-pills">
                  <li className="col-md-4 p-0">
                    <a
                      className="nav-link active"
                      data-toggle="pill"
                      href="#personal-information"
                    >
                      Business Account Profile
                    </a>
                  </li>
                  <li className="col-md-4 p-0">
                    <a
                      className="nav-link"
                      data-toggle="pill"
                      href="#chang-pwd"
                    >
                      Change Password
                    </a>
                  </li>
                  <li className="col-md-4 p-0">
                    <a
                      className="nav-link"
                      data-toggle="pill"
                      href="#emailandsms"
                    >
                      Manage Your Notification Preference
                    </a>
                  </li>
                  {/* <li className="col-md-4 p-0">
                    <a
                      className="nav-link"
                      data-toggle="pill"
                      href="#manage-contact"
                    >
                      Manage Contact
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="iq-edit-list-data">
            <div className="tab-content">
              <BusinessInfoForm
                onChangeHandler={onChangeHandler}
                data={contact}
                submitHandler={handleUpdateSubmit}
                industries={industries}
                states={states}
                areas={aop}
                lcdas={lcdas}
                countries={countries}
                loading={isUpdating}
              />
              <ChangePasswordForm />
              <div className="tab-pane fade" id="emailandsms" role="tabpanel">
                <div className="iq-card">
                  <div className="iq-card-header d-flex justify-content-between">
                    <div className="iq-header-title">
                      <h4 className="card-title">
                        NOTIFICATIONS YOU'LL RECEIVE
                      </h4>
                    </div>
                  </div>
                  <div className="iq-card-body">
                    <form>
                      <div className="form-group row align-items-center">
                        <label className="col-md-3" htmlFor="emailnotification">
                          Monthly Newsletter Notification:
                        </label>
                        <CustomSwitch value={false} onChange={() => {}} />
                      </div>
                      <div className="form-group row align-items-center">
                        <label className="col-md-3" htmlFor="smsnotification">
                          New Updates on Bikee Notification:
                        </label>
                        <CustomSwitch value={false} onChange={() => {}} />
                      </div>

                      <button type="submit" className="btn btn-primary mr-2">
                        Submit
                      </button>
                      <button type="reset" className="btn iq-bg-danger">
                        cancel
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
