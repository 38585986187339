import React, { useEffect } from "react";
import moment from "moment";
import Button from "../Button";
import styles from "./styles";
import EmptyStateWrapper from "../EmptyStateWrapper";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import IconButton from "../IconButton";
import InputField from "../InputField";
import { useDispatch, useSelector } from "react-redux";
import { CustomSwitch } from "../components";
import { RollLoader } from "../lottie/loader/Loading";
import { set } from "firebase/database";
import AppColors from "../../utils/AppColors";
import { Capitalize } from "../../utils/helpers";
import { paginationHandler } from "../../Redux/ConfigSlice";
const ResponsiveTable = ({
  rows,
  columns,
  hasNextPage,
  hasPreviousPage,
  prev_page_num = 1,
  next_page_num = 1,
  page = 1,
  limit = 10,
  total,
  selections = [],
}) => {
  const dispatch = useDispatch();

  const onFetchPreviousPage = () => {
    if (!hasPreviousPage) return;
    dispatch(paginationHandler(prev_page_num));
  };

  const onFetchNextPage = () => {
    if (!hasNextPage) return;
    dispatch(paginationHandler(next_page_num));
  };

  const switchItems = useSelector((state) => state?.Config?.switchItems);

  useEffect(() => {
    return () => {
      dispatch(paginationHandler(1));
    };
  }, []);

  return (
    <React.Fragment>
      {rows &&
      Array.isArray(rows) &&
      rows.length &&
      columns &&
      Array.isArray(columns) ? (
        <div className="table-responsive">
          <div style={styles.icon_container_wrapper}>
            <div style={styles.icon_container}>
              <IconButton
                icon={<FiChevronLeft color={AppColors.white} />}
                onClick={onFetchPreviousPage}
                disabled={!hasPreviousPage}
              />
              <IconButton
                icon={<FiChevronRight color={AppColors.white} />}
                onClick={onFetchNextPage}
                disabled={!hasNextPage}
              />
            </div>
          </div>
          <div style={styles.pagination}>
            <div style={styles.pagination_text_wrapper}>
              <p style={styles.pagination_text}>
                {page === 1 ? page : page * limit - limit} - {page * limit} of{" "}
                {total} record{total > 1 ? "s" : ""}
              </p>
            </div>
          </div>
          <table className="table table-bordered table-responsive-md table-striped text-center w-100">
            <thead>
              <tr>
                {columns.map((col, i) => (
                  <th scope="col" key={i}>
                    {col?.title ? Capitalize(col?.title) : ""}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {rows.map((el, key) => (
                <React.Fragment key={key}>
                  <tr>
                    {columns.map((col, i) => (
                      <td key={i}>
                        {col?.is_date_time && el?.[col?.field] ? (
                          moment(el?.[col?.field]).format("DD/MM/YYYY h:mm a")
                        ) : col?.is_date && el?.[col?.field] ? (
                          moment(el?.[col?.field]).format("DD/MM/YYYY")
                        ) : col?.is_full_name && col?.field ? (
                          `${
                            el?.[col?.field]?.["first_name"]
                              ? Capitalize(el?.[col?.field]?.["first_name"])
                              : ""
                          } ${
                            el?.[col?.field]?.["last_name"]
                              ? Capitalize(el?.[col?.field]?.["last_name"])
                              : ""
                          }`.trim()
                        ) : col?.is_button ? (
                          <Button
                            style={styles.button}
                            text={
                              el?.[col?.field]?.[col?.subfield]
                                ? Capitalize(el?.[col?.field]?.[col?.subfield])
                                : col?.btn_text
                                ? Capitalize(col?.btn_text)
                                : ""
                            }
                            onClick={() => {
                              col?.onClick(el);
                            }}
                          />
                        ) : col?.is_switch ? (
                          <React.Fragment>
                            {switchItems?.includes(`${col?.type}${el?.id}`) ? (
                              <div className="d-flex justify-content-center">
                                <RollLoader width={"50%"} />
                              </div>
                            ) : (
                              <CustomSwitch
                                value={
                                  el?.[col?.field] === "locked" ? true : false
                                }
                                onChange={() => col?.onToggle(col?.type, el)}
                              />
                            )}
                          </React.Fragment>
                        ) : col?.is_checkbox ? (
                          <InputField
                            type={"checkbox"}
                            style={styles.checkbox}
                            onChange={() => col?.onCheck(el)}
                            checked={selections.includes(el?.id)}
                          />
                        ) : el?.[col?.field]?.[col?.subfield] ? (
                          Capitalize(el?.[col?.field]?.[col?.subfield])
                        ) : el?.[col?.field] ? (
                          Capitalize(el?.[col?.field])
                        ) : (
                          "----"
                        )}
                      </td>
                    ))}
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <EmptyStateWrapper />
      )}
    </React.Fragment>
  );
};

export default ResponsiveTable;
