import React from "react";
import { Capitalize } from "../../utils/helpers";

const SelectField = ({
  options,
  onChange,
  value,
  placeholder,
  style,
  name,
  disabled,
}) => {
  return (
    <select
      className="form-control"
      // id="exampleFormControlSelect1"
      onChange={onChange}
      value={value || ""}
      name={name}
      style={style}
      disabled={disabled}
    >
      <option value="">{placeholder}</option>
      {options && Array.isArray(options)
        ? options?.map((opt, i) => (
            <option
              value={`${
                typeof opt === "string"
                  ? opt
                  : opt?.serial_number
                  ? opt?.serial_number
                  : opt.id
              }`}
              key={i}
            >
              {typeof opt === "string"
                ? opt
                : opt?.name
                ? Capitalize(opt?.name)
                : opt?.serial_number
                ? opt?.serial_number
                : ""}
            </option>
          ))
        : null}
    </select>
  );
};

export default SelectField;
