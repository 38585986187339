const styles = {
  card_img: {
    width: "100%",
    height: "auto",
  },
  card_img_container: {
    height: "8rem",
    width: "10rem",
    margin: "auto",
    overflow: "hidden",
  },
  card_icon_container: { height: "100px", height: "100px" },
};

export default styles;
