import { createSlice } from "@reduxjs/toolkit";

const configSlice = createSlice({
  name: "Config",
  initialState: {
    switchItems: [],
    page: 1,
  },
  reducers: {
    onToggleSwitch: (state, action) => {
      return { ...state, switchItems: action.payload };
    },
    paginationHandler: (state, action) => {
      return { ...state, page: action.payload };
    },
  },
});

export const { onToggleSwitch, paginationHandler } = configSlice.actions;
export default configSlice.reducer;
