import React, { useEffect } from "react";
import { NotificationContainer } from "react-notifications";
import Login from "./pages/auth/Login";
import Dashboard from "./pages/fleet/Dashboard";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "react-notifications/lib/notifications.css";
import "./App.css";
import Realtime from "./pages/fleet/Realtime";
import BikeList from "./pages/fleet/BikeList";
import HeatMap from "./pages/fleet/HeapMap";
import Settings from "./pages/fleet/Settings";
import Forgot from "./pages/auth/Forgot";
import Reports from "./pages/fleet/Reports";
import { QueryClient, QueryClientProvider } from "react-query";
import NotFound from "./pages/NotFound";
import ProtectedRoute from "./components/ProtectedRoute";
import { Provider } from "react-redux";
import store from "./Redux/index";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import GeoFence from "./pages/fleet/GeoFencing";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 2,
      refetchOnWindowFocus: false,
      //cacheTime: 1000 * 250 * 60, //cache expires in 5 minutes
      staleTime: 1000 * 0.5 * 60, //fetch new records every 0.5 minutes for stale records.
    },
  },
});

function App() {
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <React.Fragment>
          <NotificationContainer />
          <Router>
            <Switch>
              <Route exact path="/" component={Login} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/forgot" component={Forgot} />
              <ProtectedRoute exact path="/dashboard" component={Dashboard} />
              <ProtectedRoute exact path="/realtime" component={Realtime} />
              <ProtectedRoute exact path="/bike_list" component={BikeList} />
              <ProtectedRoute exact path="/heat_map" component={HeatMap} />
              <ProtectedRoute exact path="/settings" component={Settings} />
              <ProtectedRoute exact path="/geofence" component={GeoFence} />
              <ProtectedRoute exact path="/reports" component={Reports} />
              <Route path="*" component={NotFound} />
            </Switch>
          </Router>
        </React.Fragment>
      </QueryClientProvider>
    </Provider>
  );
}

export default App;
