import React from "react";
import styles from "./styles";

const IconButton = ({ disabled, icon, onClick, style = {} }) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      style={{ ...styles.icon, ...(disabled ? styles.disabled : {}), ...style }}
    >
      {icon}
    </button>
  );
};

export default IconButton;
