import React, { useEffect, useState } from "react";
import Switch from "react-input-switch";
import { getAPIs } from "../utils/api";
import { ToastError } from "../utils/helpers";

export const FilterBox = ({ states, setFilteredList }) => {
  const [lcdas, setLcdas] = useState([]);
  const [aop, setAops] = useState([]);
  const [selectedAop, setSelectedAop] = useState("");
  const getLcda = async (id) => {
    let res = await getAPIs(`/state-lcdas/${id}`);
    setLcdas(res?.data?.data);
  };
  const getAop = async (id) => {
    let res = await getAPIs(`/lcda/${id}`);
    setAops(res?.data?.area_of_ops);
  };
  const getAnAop = async (id) => {
    let res = await getAPIs(`/lcda-areas/${id}`);
    setSelectedAop(res?.data.data);
    setFilteredList(res?.data.data);
  };
  return (
    <div
      className="tab-pane fade active show"
      id="personal-information"
      role="tabpanel"
    >
      <div className="iq-card">
        <div className="iq-card-body">
          <form>
            <div className="row align-items-center">
              <div className="form-group col-sm-4">
                <label>Select State:</label>
                <select
                  className="form-control"
                  id="exampleFormControlSelect1"
                  onChange={(e) => {
                    setLcdas([]);
                    getLcda(e.target.value);
                  }}
                >
                  <option value="">Select State</option>
                  {states?.map((area) => (
                    <option value={area.id} key={area?.id}>
                      {area?.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group col-sm-4">
                <label>Select LCDA:</label>
                <select
                  className="form-control"
                  id="exampleFormControlSelect1"
                  onChange={(e) => {
                    setAops([]);
                    getAop(e.target.value);
                  }}
                >
                  <option value="">Select LCDA</option>
                  {lcdas &&
                    lcdas.map((lcda) => (
                      <option value={`${lcda.id}`} key={lcda.id}>
                        {lcda?.name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="form-group col-sm-4">
                <label>Select Area of Operation:</label>
                <select
                  className="form-control"
                  id="exampleFormControlSelect1"
                  onChange={(e) => {
                    getAnAop(e.target.value);
                  }}
                >
                  {aop.length < 1 && <option>No Area of Operation</option>}
                  {aop.length > 0 && <option>Select Area of Operation</option>}
                  {aop?.map((area, index) => (
                    <option value={`${area.lcda_id}`} key={index}>
                      {area?.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export const CustomSwitch = (props) => (
  <Switch
    onChange={props.onChange}
    value={props.value}
    on={true}
    off={false}
    styles={{
      track: {
        backgroundColor: "#dee2e6",
      },
      trackChecked: {
        backgroundColor: "#ff2626",
      },
      button: {
        backgroundColor: "#ff2626",
      },
      buttonChecked: {
        backgroundColor: "#dee2e6",
      },
    }}
  />
);
