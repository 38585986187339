import React from 'react'
import Lottie from 'react-lottie';
import LoaderIcon from './loader.json'
import Roll from './roll.json'

export const Loading = () => {
    const buttonStyle = {
        display: 'block',
        margin: '10px auto'
      };
   
    const defaultOptions = {
        loop: true,
        autoplay: true, 
        animationData: LoaderIcon,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    return(
        <Lottie 
            options={defaultOptions}
            height={80}
              width={80}
        />
    )
}

export const RollLoader = () => {
    const buttonStyle = {
        display: 'block',
        margin: '10px auto'
      };
   
    const defaultOptions = {
        loop: true,
        autoplay: true, 
        animationData: Roll,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    return(
        <Lottie 
            options={defaultOptions}
            height={50}
              width={50}
        />
    )
}