import { useQuery } from "react-query";
import {
  GEOFENCES,
  GET_ASSIGNED_BIKES,
  GET_BIKE_REPORTS,
  GET_CONTACT,
  GET_COUNTRIES,
  GET_COUNTRY_STATES,
  GET_FLEET_METRIC,
  GET_INDUSTRIES,
  GET_LCDA_AREAS,
  GET_LOGS,
  GET_STATE_LCDA,
} from "./query_keys";
import { APIFunctions } from "./api";
import { __flatten, queryResponse } from "./helpers";
import { useSelector } from "react-redux";
import { useMemo } from "react";

export const useFetchFleetMetric = () => {
  let user = JSON.parse(localStorage.getItem("user"));
  return useQuery(
    [GET_FLEET_METRIC],
    () => APIFunctions.fleet_metric(user?.client_id?.id),
    {
      enabled: !!user?.client_id?.id,
    }
  );
};

export const useFetchContact = () => {
  const { client_id } = JSON.parse(localStorage.getItem("user")) || {};
  return useQuery(
    [GET_CONTACT],
    () => APIFunctions.get_contact(client_id?.id),
    {
      enabled: !!client_id?.id,
    }
  );
};

export const useFetchCountries = (num) => {
  const page = useSelector((state) => num || state.Config.page);
  const query = useQuery({
    queryFn: () => APIFunctions.get_countries(page),
    queryKey: [GET_COUNTRIES, page],
  });
  let data = __flatten(query?.data);
  return useMemo(() => queryResponse(data, query), [data, query?.isFetching]);
};

export const useFetchCountryStates = (id, num) => {
  const page = useSelector((state) => num || state.Config.page);
  const query = useQuery({
    queryFn: () => APIFunctions.get_country_states(page, id),
    enabled: !!id,
    queryKey: [GET_COUNTRY_STATES, page, id],
  });
  let data = __flatten(query?.data);
  return useMemo(() => queryResponse(data, query), [data, query?.isFetching]);
};

export const useFetchStateLCDAs = (id, num) => {
  const page = useSelector((state) => num || state.Config.page);
  const query = useQuery({
    queryFn: () => APIFunctions.get_state_lcdas(page, id),
    enabled: !!id,
    queryKey: [GET_STATE_LCDA, page, id],
  });
  let data = __flatten(query?.data);
  return useMemo(() => queryResponse(data, query), [data, query?.isFetching]);
};

export const useFetchLcdaAreas = (id, num) => {
  const page = useSelector((state) => num || state.Config.page);
  const query = useQuery({
    queryFn: () => APIFunctions.get_lcda_areas(page, id),
    enabled: !!id,
    queryKey: [GET_LCDA_AREAS, page, id],
  });
  let data = __flatten(query?.data);
  return useMemo(() => queryResponse(data, query), [data, query?.isFetching]);
};

export const useFetchIndustries = (num) => {
  const page = useSelector((state) => num || state.Config.page);
  const query = useQuery({
    queryFn: () => APIFunctions.get_industries(page),
    queryKey: [GET_INDUSTRIES, page],
  });
  let data = __flatten(query?.data);
  return useMemo(() => queryResponse(data, query), [data, query?.isFetching]);
};

export const useFetchAssignedBikes = (id, num) => {
  const page = useSelector((state) => num || state.Config.page);
  const query = useQuery({
    queryFn: () => APIFunctions.get_assigned_bikes(page, id),
    queryKey: [GET_ASSIGNED_BIKES, page, id],
    enabled: !!id,
  });
  let data = __flatten(query?.data);
  return useMemo(() => queryResponse(data, query), [data, query?.isFetching]);
};

export const useFetchBikeReports = (
  client_id,
  bike_sn,
  start_time,
  end_time,
  num
) => {
  const page = useSelector((state) => num || state.Config.page);
  const query = useQuery({
    queryFn: () =>
      APIFunctions.get_bike_reports(
        page,
        client_id,
        bike_sn,
        start_time,
        end_time
      ),
    queryKey: [
      GET_BIKE_REPORTS,
      page,
      client_id,
      bike_sn,
      start_time,
      end_time,
    ],
    enabled: !!client_id && !!bike_sn && !!end_time && !!start_time,
  });
  let data = __flatten(query?.data);
  return useMemo(() => queryResponse(data, query), [data, query?.isFetching]);
};

export const useFetchBikePulseReports = (
  client_id,
  bike_sn,
  start_time,
  end_time,
  num
) => {
  const page = useSelector((state) => num || state.Config.page);
  const query = useQuery({
    queryFn: () =>
      APIFunctions.get_pulse_reports(
        page,
        client_id,
        bike_sn,
        start_time,
        end_time
      ),
    queryKey: [
      GET_BIKE_REPORTS,
      page,
      client_id,
      bike_sn,
      start_time,
      end_time,
    ],
    enabled: !!client_id && !!bike_sn && !!end_time && !!start_time,
  });
  let data = __flatten(query?.data);
  return useMemo(() => queryResponse(data, query), [data, query?.isFetching]);
};

export const useFetchLogs = (id, num) => {
  const page = useSelector((state) => num || state.Config.page);
  const query = useQuery({
    queryFn: () => APIFunctions.get_logs(page, id),
    refetchInterval: 200,
    queryKey: [GET_LOGS, page, id],
    enabled: !!id,
  });
  let data = __flatten(query?.data);
  return useMemo(() => queryResponse(data, query), [data, query?.isFetching]);
};

export const useFetchClientGeofence = () => {
  const { client_id } = JSON.parse(localStorage.getItem("user")) || {};
  return useQuery({
    queryKey: [GEOFENCES, client_id?.id],
    queryFn: () => APIFunctions.geofences(client_id?.id),
    enabled: !!client_id?.id,
  });
};
