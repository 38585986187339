import React from "react";

const PageLoader = () => {
  return (
    <div id="loading">
      <div id="loading-center"></div>
    </div>
  );
};

export default PageLoader;
