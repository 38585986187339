import numeral from "numeral";
import React, { useMemo } from "react";
import { ToastSuccess, __flatten } from "../../../utils/helpers";
import PageLoader from "../../../components/Loader";
import { useFetchAssignedBikes } from "../../../utils/queries";
import ResponsiveTable from "../../../components/ResponsiveTable";
import { useDispatch, useSelector } from "react-redux";
import { onToggleSwitch } from "../../../Redux/ConfigSlice";
import {
  usePostLockBattery,
  usePostLockBike,
  usePostUnLockBattery,
  usePostUnLockBike,
} from "../../../utils/mutations";

const BikeList = () => {
  const dispatch = useDispatch();
  const switchItems = useSelector((state) => state?.Config?.switchItems);

  const onCompleteUsePostUnlockBattery = () => {
    ToastSuccess("Update has been saved");
    dispatch(onToggleSwitch([]));
  };

  const onError = () => {
    dispatch(onToggleSwitch([]));
  };

  const { mutate: lock } = usePostLockBike(
    onCompleteUsePostUnlockBattery,
    onError
  );

  const { mutate: unlock } = usePostUnLockBike(
    onCompleteUsePostUnlockBattery,
    onError
  );

  const { mutate: lockBattery } = usePostLockBattery(
    onCompleteUsePostUnlockBattery,
    onError
  );

  const { mutate: unlockBattery } = usePostUnLockBattery(
    onCompleteUsePostUnlockBattery,
    onError
  );

  const { client_id } = useMemo(
    () => JSON.parse(localStorage.getItem("user")) || {},
    []
  );

  const {
    data: bikes,
    isLoading,
    prev_page_num,
    hasNextPage,
    limit,
    hasPreviousPage,
    next_page_num,
    page,
    total,
  } = useFetchAssignedBikes(client_id?.id);

  const onToggleBikeLock = (type, bike) => {
    if (bike?.lock_status === "locked") {
      let arr = [...switchItems];
      arr.splice(arr.indexOf(`${type}${bike?.id}`), 1);
      unlock(bike?.serial_number);
      return dispatch(onToggleSwitch(arr));
    }
    let arr = [...switchItems, `${type}${bike?.id}`];
    lock(bike?.serial_number);
    dispatch(onToggleSwitch(arr));
  };

  // const onToggleBatteryLock = (type, bike) => {
  //   if (switchItems.includes(`${type}${bike?.id}`)) {
  //     let arr = [...switchItems];
  //     arr.splice(arr.indexOf(`${type}${bike?.id}`), 1);
  //     unlockBattery(bike?.serial_number);
  //     return dispatch(onToggleSwitch(arr));
  //   }
  //   let arr = [...switchItems, `${type}${bike?.id}`];
  //   lockBattery(bike?.serial_number);
  //   dispatch(onToggleSwitch(arr));
  // };

  const columns = [
    {
      title: "Bike number",
      field: "serial_number",
    },
    {
      title: "address",
      field: "street_address",
    },
    {
      title: "last active",
      field: "last_active",
      is_date_time: true,
    },
    // {
    //   title: "last rider's name",
    //   field: "last_user_name",
    // },
    {
      title: "status",
      field: "lock_status",
    },
    {
      title: "battery level",
      field: "battery_charge",
    },
    // {
    //   title: "force lock / unlock battery",
    //   is_switch: true,
    //   onToggle: onToggleBatteryLock,
    // },
    {
      title: "force lock / unlock bike",
      onToggle: onToggleBikeLock,
      type: "bike",
      field: "lock_status",
      is_switch: true,
    },
  ];

  return (
    <div className="container-fluid">
      {isLoading ? <PageLoader /> : null}
      <div className="row">
        <div className="col-sm-12">
          <div className="iq-card">
            <div className="iq-card-header d-flex justify-content-between">
              <div className="iq-header-title">
                <h4 className="card-title">
                  Total List of e-bikes in operation (Full Battery Level Must Be 65535mAh) : {" "}
                  {bikes &&
                    Array.isArray(bikes) &&
                    numeral(bikes.length).format("0,0")}
                </h4>
              </div>
            </div>
            <div className="iq-card-body">
              <ResponsiveTable
                rows={bikes}
                columns={columns}
                hasNextPage={hasNextPage}
                hasPreviousPage={hasPreviousPage}
                limit={limit}
                total={total}
                page={page}
                prev_page_num={prev_page_num}
                next_page_num={next_page_num}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BikeList;
