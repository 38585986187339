import { configureStore } from "@reduxjs/toolkit";
import ConfigSlice from "./ConfigSlice";

const store = configureStore({
  reducer: {
    Config: ConfigSlice,
  },
});

export default store;
