import axios from "axios";

export const baseUrl = process.env.REACT_APP_BASE_URL;
export const BASE_URL =
  "https://motion-fleets-ci-cd-letp5hkt7a-uc.a.run.app/api/";
const IOT_BASE_URL = process.env.REACT_APP_IOT_BASE_URL;
const API_KEY = process.env.REACT_APP_IOT_API_KEY;

export const APIFunctions = {
  get_contact: async (client_id) => {
    return getAPIs(`/clients/${client_id}`);
  },
  get_industries: async (page) => getAPIs(`/industries/?page=${page}`),
  get_countries: async (page) => getAPIs(`/countries/?page=${page}`),
  get_country_states: async (page, id) =>
    getAPIs(`/country-states/${id}/?page=${page}`),
  get_state_lcdas: async (page, id) => {
    return getAPIs(`/state-lcdas/${id}/?page=${page}`);
  },
  get_lcda_areas: async (page, id) => {
    return getAPIs(`/lcda-areas/${id}/?page=${page}`);
  },
  update_client_info: async (load) => {
    const { client_id } = JSON.parse(localStorage.getItem("user"));
    return putAPIs(`/clients/${client_id?.id}`, load);
  },
  change_password: async (load) => {
    return postAPIs(`/change-password`, load);
  },
  get_assigned_bikes: async (page, id) => {
    return getAPIs(`/get-assigned/${id}/?page=${page}`, true);
  },
   get_bike_reports: async (page, client_id, bike_sn, start_time, end_time) => {
    return getAPIs(
      `/get-reports/${client_id}/${bike_sn}/${start_time}/${end_time}/${page}`,
      true
    );
  },
  get_pulse_reports: async (page, client_id, bike_sn, start_time, end_time) => {
    return getAPIs(
      `/get-pulse-reports/${client_id}/${bike_sn}/${start_time}/${end_time}/${page}`,
      true
    );
  },
  unlock_battery: async (id) => {
    return postAPIs(`/unlock-battery/${id}`, {}, true);
  },

  lock_vehicle: async (id) => {
    return postAPIs(`/lock-vehicle/${id}`, {}, true);
  },

  unlock_vehicle: async (id) => {
    return postAPIs(`/unlock-vehicle/${id}`, {}, true);
  },

  lock_battery: async (id) => {
    return postAPIs(`/lock-battery/${id}`, {}, true);
  },
  get_logs: async (page, client_id) => {
    return getAPIs(`/logs/${client_id}/?page=${page}`);
  },
  fleet_metric: async (client_id) => {
    return getAPIs(`/fleet-metrics/${client_id}`);
  },
  set_alarm: async (serial_num) => {
    return postAPIs(`/set-alarm/${serial_num}`, {}, true);
  },
  remove_alarm: async (serial_num) => {
    return postAPIs(`/remove-alarm/${serial_num}`, {}, true);
  },
  geofences: async (client_id) => {
    return getAPIs(`/get-geofences/${client_id}`, true);
  },
  create_geofence: async (fd) => {
    return postAPIs(`/create-geofence/${fd?.client_id}`, fd, true);
  },
  update_geofence: async (fd) => {
    return putAPIs(`/update-geofence/${fd?.id}`, fd, true);
  },
};

export const getAPIs = async (path, is_iot) => {
  let token = localStorage.getItem("token");
  return new Promise((resolve, reject) => {
    let url = baseUrl;
    if (is_iot) url = IOT_BASE_URL;
    axios
      .get(`${url}${path}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Cache-Control": "no-cache, no-store, must-revalidate",
          Pragma: "no-cache",
          Expires: 0,
          "x-api-key": API_KEY,
        },
      })
      .then((result) => {
        resolve(result?.data);
      })
      .catch((error) => {
        let code = error.code;
        let msg = error.message;

        if (error.message === "Request failed with status code 401") {
          localStorage.clear();
          window.location.href = "/login";
        }
        reject({ status: code, msg: msg });
      });
  });
};

export const postAPIs = async (path, payload, is_iot) => {
  let token = localStorage.getItem("token");
  return new Promise((resolve, reject) => {
    let url = baseUrl;
    if (is_iot) url = IOT_BASE_URL;
    axios
      .post(`${url}${path}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Cache-Control": "no-cache, no-store, must-revalidate",
          Pragma: "no-cache",
          "x-api-key": API_KEY,
        },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        let code = error.code;
        let msg = error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.response?.data?.message?.[0]
          ? error?.response?.data?.message?.[0]
          : error.message;
        if (error.message === "Request failed with status code 401") {
          localStorage.clear();
          window.location.href = "/login";
        }
        reject({ status: code, msg: msg });
      });
  });
};

export const putAPIs = async (path, payload, is_iot) => {
  let token = localStorage.getItem("token");
  let url = baseUrl;
  if (is_iot) url = IOT_BASE_URL;
  return new Promise((resolve, reject) => {
    axios
      .put(`${url}${path}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Cache-Control": "no-cache, no-store, must-revalidate",
          Pragma: "no-cache",
          "x-api-key": API_KEY,
        },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        let code = error.code;
        let msg = error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.response?.data?.message?.[0]
          ? error?.response?.data?.message?.[0]
          : error.message;
        if (error.message === "Request failed with status code 401") {
          localStorage.clear();
          window.location.href = "/login";
        }
        reject({ status: code, msg: msg });
      });
  });
};
export const deleteAPIs = async (path, payload) => {
  let token = localStorage.getItem("token");
  return new Promise((resolve, reject) => {
    axios
      .delete(`${baseUrl}${path}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Cache-Control": "no-cache, no-store, must-revalidate",
          Pragma: "no-cache",
        },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        let code = error.code;
        let msg = error.message;

        if (error.message === "Request failed with status code 401") {
          localStorage.clear();
          window.location.href = "/login";
        }
        reject({ status: code, msg: msg });
      });
  });
};
