import React, { useMemo, useState } from "react";
import { MapContainer } from "../../../components/Map";
import { Capitalize, __flatten } from "../../../utils/helpers";
import numeral from "numeral";
import { CustomSwitch } from "../../../components/components";
import PageLoader from "../../../components/Loader";
import styles from "./styles";
import { RollLoader } from "../../../components/lottie/loader/Loading";
import {
  useFetchAssignedBikes,
  useFetchContact,
  useFetchFleetMetric,
} from "../../../utils/queries";
import {
  usePostLockBattery,
  usePostLockBike,
  usePostUnLockBattery,
  usePostUnLockBike,
} from "../../../utils/mutations";
import { useSetBikeAlarm } from "../../../utils/mutations";
import { useRemoveBikeAlarm } from "../../../utils/mutations";

const Dashboard = () => {
  const [selectBike, setSelectedBike] = useState(null);
  const { client_id } = useMemo(
    () => JSON.parse(localStorage.getItem("user")) || {}
  );

  const { mutate: unlockBattery, isLoading: isUnLockingBattery } =
    usePostUnLockBattery();

  const { mutate: lockBattery, isLoading: isLockingBattery } =
    usePostLockBattery();

  const { mutate: unlockBike, isLoading: isUnLockingBike } =
    usePostUnLockBike();

  const { mutate: lockBike, isLoading: isLockingBike } = usePostLockBike();

  const { mutate: setAlarm, isLoading: isSetting } = useSetBikeAlarm();

  const { mutate: removeAlarm, isLoading: isRemoving } = useRemoveBikeAlarm();

  const handleSelectedBike = (bike) => {
    setSelectedBike(bike);
  };

  const handleLockBike = () => {
    lockBike(selectBike?.serial_number);
  };
  const handleUnLockBike = () => {
    unlockBike(selectBike?.serial_number);
  };

  const handleLockBikeBattery = () => {
    lockBattery(selectBike?.serial_number);
  };
  const handleUnLockBikeBattery = () => {
    unlockBattery(selectBike?.serial_number);
  };

  const handleRemoveBikeAlarm = () => {
    removeAlarm(selectBike?.serial_number);
  };

  const handleSetBikeAlarm = () => {
    setAlarm(selectBike?.serial_number);
  };

  const { data: metrics, isLoading } = useFetchFleetMetric();

  const { data: bikes, isLoading: loadingBikes } = useFetchAssignedBikes(
    client_id?.id
  );

  const { data: contact, isLoading: loadingContact } = useFetchContact();

  return (
    <div className="container-fluid">
      <div className="row">
        {isLoading || loadingBikes || loadingContact ? <PageLoader /> : null}
        <div className="col-lg-4">
          <div className="iq-card iq-card-block iq-card-stretch iq-card-height iq-user-profile-block">
            <div className="iq-card-body">
              <div className="user-details-block">
                <div className="text-center mt-3">
                  {contact?.data?.name ? (
                    <h4>
                      <b>{Capitalize(contact?.data?.name)}</b>
                    </h4>
                  ) : null}
                  <p>
                    {contact?.data?.address
                      ? Capitalize(contact?.data?.address)
                      : null}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="iq-card iq-card-block iq-card-stretch iq-card-height iq-user-profile-block">
            <div className="iq-card-body">
              <div className="user-details-block">
                <div className="user-profile text-center">
                  <div style={styles.card_img_container}>
                    <img
                      src="/assets/images/user/studentbike.png"
                      alt="profile-img"
                      style={styles.card_img}
                    />
                  </div>
                </div>
                <div className="text-center mt-3">
                  <h4>
                    <b>Solution Offered:</b>
                  </h4>
                </div>
                <hr />
                <ul className="doctoe-sedual d-flex align-items-center justify-content-between p-0">
                  <li className="text-center">
                    <h4 className="">
                      {metrics?.solution_offered
                        ? Capitalize(metrics?.solution_offered)
                        : null}
                    </h4>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="iq-card iq-card-block iq-card-stretch iq-card-height iq-user-profile-block">
            <div className="iq-card-body">
              <div className="user-details-block">
                <div className="user-profile text-center">
                  <div style={styles.card_img_container}>
                    <img
                      src="/assets/images/page-img/46.png"
                      alt="profile-img"
                      style={styles.card_img}
                    />
                  </div>
                </div>
                <div className="text-center mt-3">
                  <h4>
                    <b>Total number of riders:</b>
                  </h4>
                </div>
                <hr />
                <ul className="doctoe-sedual d-flex align-items-center justify-content-between p-0">
                  <li className="text-center">
                    <h3 className="counter">
                      {metrics?.total_riders
                        ? numeral(metrics?.total_riders).format("0,0")
                        : "2"}
                    </h3>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="row">
            {[
              // {
              //   text: "Total number of rides daily (guided by lock/unlock from app)",
              //   value: metrics?.total_rides
              //     ? numeral(metrics?.total_rides).format("0,0")
              //     : "0",
              //   icon: "ri-user-fill",
              //   icon_color: "bg-primary",
              //   bg: "iq-bg-primary",
              // },
              // {
              //   text: "Total number of riders daily (24 HRS)",
              //   value: metrics?.total_rides
              //     ? numeral(metrics?.total_rides).format("0,0")
              //     : 0,
              //   icon: "ri-women-fill",
              //   icon_color: "bg-warning",
              //   bg: "iq-bg-warning",
              // },
              // {
              //  text: "Total distance covered (km)",
              //  value: metrics?.total_distance
              //    ? numeral(metrics?.total_distance).format("0,0")
              //    : 0,
              //  icon: "ri-hospital-line",
              //  icon_color: "bg-danger",
              //  bg: "iq-bg-danger",
              // },
              // {
              // text: "Total calories burned (kcal)",
              //  value: metrics?.total_calories
              //    ? numeral(metrics?.total_calories).format("0,0")
              //    : 0,
              //  icon: "ri-hospital-line",
              //  icon_color: "bg-info",
              //  bg: "iq-bg-info",
              // },
              // {
              //  text: "Total estimated CO2 reduction (kg)",
              //  value: metrics?.total_co2
              //    ? numeral(metrics?.total_co2).format("0,0")
              //    : "0",
              //  icon: "ri-hospital-line",
              //  icon_color: "bg-primary",
              //  bg: "iq-bg-primary",
              // },
            ].map((item, i) => (
              <div className="col-md-6 col-lg-4" key={i}>
                <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
                  <div className={`iq-card-body ${item?.bg} rounded`}>
                    <div className="d-flex align-items-center justify-content-between">
                      <div style={styles.card_icon_container}>
                        <div
                          className={`rounded-circle iq-card-icon ${item?.icon_color}`}
                        >
                          <i className={item?.icon}></i>
                        </div>
                      </div>
                      <div className="text-right">
                        <h2 className="mb-0">
                          <span className="counter">{item?.value}</span>
                        </h2>
                        <h5 className="">{item?.text}</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="col-lg-12">
          <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
            <div className="iq-card-header d-flex justify-content-between">
              <div className="iq-header-title">
                <h4 className="card-title">E-bike Location Map in Real-time.</h4>
              </div>
            </div>
            <div className="col-lg-12 iq-card-body">
              <div className="mapouter">
                <div className="gmap_canvas">
                  <MapContainer
                    setBikeInfo={null}
                    bikes={bikes}
                    setBike={handleSelectedBike}
                  />
                </div>
              </div>
            </div>
            {selectBike ? (
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
                      <div className="iq-card-header d-flex justify-content-between">
                        <div className="iq-card">
                          <div className="iq-card-body">
                            <form>
                              <div className=" row align-items-center">
                                <div className="form-group col-sm-3">
                                  <label>
                                    Serial No:{" "}
                                    {selectBike && selectBike.serial_number}
                                  </label>
                                </div>
                                <div className="form-group col-sm-3">
                                  <label>
                                    Battery:{" "}
                                    {selectBike && selectBike.battery_charge}
                                  </label>
                                </div>
                                <div className="form-group col-sm-3">
                                  <label>
                                    Software Version: {selectBike && "10.3.9"}
                                  </label>
                                </div>
                                <div className="form-group col-sm-3">
                                  <label>
                                    Status:{" "}
                                    {selectBike && selectBike.lock_status
                                      ? Capitalize(selectBike.lock_status)
                                      : ""}
                                  </label>
                                </div>
                                {/* <div className="form-group col-sm-3">
                                  <label>
                                    Rider's Name :{" "}
                                    {selectBike && selectBike.riders_name
                                      ? Capitalize(selectBike.riders_name)
                                      : ""}
                                  </label>
                                </div> */}
                                <div className="form-group col-sm-3">
                                  <label>
                                    Current geo-location:{" "}
                                    {selectBike?.street_address
                                      ? Capitalize(selectBike?.street_address)
                                      : ""}
                                  </label>
                                </div>
                                <div className="form-group col-sm-3">
                                  <label> Lock / Unlock Bike</label>
                                  <div>
                                    {isUnLockingBike || isLockingBike ? (
                                      <RollLoader />
                                    ) : (
                                      <CustomSwitch
                                        value={
                                          selectBike &&
                                          selectBike?.lock_status === "locked"
                                            ? true
                                            : false
                                        }
                                        onChange={
                                          selectBike &&
                                          selectBike?.lock_status === "locked"
                                            ? handleUnLockBike
                                            : handleLockBike
                                        }
                                      />
                                    )}
                                  </div>
                                </div>
                                <div className="form-group col-sm-3">
                                  <label> Lock / Unlock Battery</label>
                                  <div>
                                    {isUnLockingBattery || isLockingBattery ? (
                                      <RollLoader />
                                    ) : (
                                      <CustomSwitch
                                        value={
                                          selectBike &&
                                          selectBike.battery_lock_status ===
                                            "locked"
                                            ? true
                                            : false
                                        }
                                        onChange={
                                          selectBike &&
                                          selectBike?.battery_lock_status ===
                                            "locked"
                                            ? handleUnLockBikeBattery
                                            : handleLockBikeBattery
                                        }
                                      />
                                    )}
                                  </div>
                                </div>

                                <div className="form-group col-sm-3">
                                  <label> Set / Unset Alarm</label>
                                  <div>
                                    {isSetting || isRemoving ? (
                                      <RollLoader />
                                    ) : (
                                      <CustomSwitch
                                        value={
                                          selectBike?.alarm_status === "on"
                                            ? true
                                            : false
                                        }
                                        onChange={
                                          selectBike &&
                                          selectBike?.alarm_status === "on"
                                            ? handleRemoveBikeAlarm
                                            : handleSetBikeAlarm
                                        }
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
