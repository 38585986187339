import React, { useMemo } from "react";
import { __flatten } from "../../../utils/helpers";
import moment from "moment";
import PageLoader from "../../../components/Loader";
import ResponsiveTable from "../../../components/ResponsiveTable";
import { useFetchLogs } from "../../../utils/queries";

const Realtime = () => {
  const { client_id } = useMemo(
    () => JSON.parse(localStorage.getItem("user")) || {},
    []
  );

  const {
    data: logs,
    isLoading,
    prev_page_num,
    hasNextPage,
    limit,
    hasPreviousPage,
    next_page_num,
    page,
    total,
  } = useFetchLogs(client_id?.id);

  const columns = [
    {
      title: "Date",
      field: "created_at",
      is_date_time: true,
    },
    {
      title: "Last Rider Name",
      field: "rider",
      is_full_name: true,
    },
    {
      title: "battery level",
      field: "vehicle",
      subfield: "battery_level",
    },
    {
      title: "current location",
      field: "vehicle",
      subfield: "street_address",
    },
    {
      title: "Serial number",
      field: "vehicle",
      subfield: "serial_number",
    },
  ];

  return (
    <div className="container-fluid">
      <div className="row">
        {isLoading ? <PageLoader /> : null}
        <div className="col-lg-12">
          <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
            <div className="iq-card-header d-flex justify-content-between">
              <div className="iq-header-title mt-3">
                <h4 className="card-title">All real-time ongoing trips</h4>
                <p>
                  Today's Date and Current Time:{" "}
                  <span id="datetime">
                    <b>{moment(new Date()).format("hh:mm:ss, DD MMMM YYYY")}</b>
                  </span>
                </p>
              </div>
            </div>
            <div className="iq-card-body">
              <ResponsiveTable
                rows={logs}
                columns={columns}
                hasNextPage={hasNextPage}
                hasPreviousPage={hasPreviousPage}
                limit={limit}
                total={total}
                page={page}
                prev_page_num={prev_page_num}
                next_page_num={next_page_num}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Realtime;
