import React, { useMemo, useEffect } from "react";
import { ToastError, __flatten } from "../../../utils/helpers";
import PageLoader from "../../../components/Loader";
import {
  useFetchAssignedBikes,
  useFetchClientGeofence,
  useFetchContact,
} from "../../../utils/queries";
import Button from "../../../components/Button";
import MapBoxComponent from "../../../components/MapBoxComponent";
import { useCreateGeofence, useUpdateGeofence } from "../../../utils/mutations";
import { RollLoader } from "../../../components/lottie/loader/Loading";

const GeoFence = () => {
  const [geometry, setGeometry] = React.useState([]);

  const { client_id } = useMemo(
    () => JSON.parse(localStorage.getItem("user")) || {},
    []
  );

  const { data: bikes, isLoading } = useFetchAssignedBikes(client_id?.id, 1);

  const { data, isLoading: loading } = useFetchClientGeofence();
  const { data: contact } = useFetchContact();

  const { mutate: create, isLoading: isCreating } = useCreateGeofence();

  const { mutate: update, isLoading: isUpdating } = useUpdateGeofence();

  const onUpdate = (coordinates) => {
    setGeometry(coordinates);
  };

  const saveChanges = () => {
    if (geometry.length === 0)
      return ToastError("Please mark out the boundary you want to geofence");
    let fd = {
      type: "polygon",
      name: `${contact?.data?.name} geofence`,
      id: data?.geofences?.[0]?.id,
      client_id: contact?.data?.id,
      coordinates: geometry?.[0],
    };
    if (data?.geofences?.[0]?.id) {
      return update(fd);
    }
    create(fd);
  };

  useEffect(() => {
    setGeometry(
      data?.geofences?.[0]?.coordinates
        ? JSON.parse(data?.geofences?.[0]?.coordinates)
        : []
    );
  }, [data]);

  return (
    <div className="container-fluid">
      {isLoading || loading ? <PageLoader /> : null}
      <div className="row">
        <div className="col-sm-12">
          <div className="iq-card">
            <div className="iq-card-header d-flex justify-content-between">
              <div className="iq-header-title">
                <div>
                  <h4 className="card-title mt-3">Geofencing</h4>
                  <p>Please mark out boundary you want to geofence</p>
                </div>
              </div>
              <div>
                {isCreating || isUpdating ? (
                  <RollLoader />
                ) : (
                  <Button text="Save changes" onClick={saveChanges} />
                )}
              </div>
            </div>
            <div className="iq-card-body">
              <MapBoxComponent
                onUpdate={onUpdate}
                bikes={bikes}
                geometry={geometry}
                onUpdateHandler={onUpdate}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeoFence;
