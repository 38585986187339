import React from "react";

const Button = ({ text, type, onClick, style }) => {
  return (
    <button
      type={type || "button"}
      className="btn btn-primary"
      style={style}
      onClick={() => {
        if (type === "submit") return;
        onClick();
      }}
    >
      {text}
    </button>
  );
};

export default Button;
