import React from "react";
import InputField from "../InputField";
import SelectField from "../SelectField";
import { RollLoader } from "../lottie/loader/Loading";
import Button from "../Button";
import styles from "./styles";

const BusinessInfoForm = ({
  data,
  onChangeHandler,
  submitHandler,
  countries,
  states,
  lcdas,
  areas,
  industries,
  loading,
}) => {
  return (
    <div
      className="tab-pane fade active show"
      id="personal-information"
      role="tabpanel"
    >
      <div className="iq-card">
        <div className="iq-card-header d-flex justify-content-between">
          <div className="iq-header-title">
            <h4 className="card-title">Business Information</h4>
          </div>
        </div>
        <div className="iq-card-body">
          <form onSubmit={submitHandler}>
            <div className="row align-items-center">
              <div className="form-group col-sm-6 ">
                <label htmlFor="Name">Business Name</label>
                <InputField
                  name={"name"}
                  onChange={onChangeHandler}
                  value={data?.name}
                  placeholder={"Enter name"}
                />
              </div>
              <div className="form-group col-sm-6 ">
                <label htmlFor="fname">First Name</label>
                <InputField
                  name={"first_name"}
                  value={data?.first_name}
                  onChange={onChangeHandler}
                  placeholder={"Enter first name"}
                />
              </div>
              <div className="form-group col-sm-6 ">
                <label htmlFor="lastname">Last Name</label>
                <InputField
                  name={"last_name"}
                  value={data?.last_name}
                  onChange={onChangeHandler}
                  placeholder={"Enter last name"}
                />
              </div>
              <div className="form-group col-sm-6 ">
                <label htmlFor="email">Enter Email</label>
                <InputField
                  type="email"
                  name={"email"}
                  value={data?.email}
                  onChange={onChangeHandler}
                  placeholder={"Enter email"}
                  disabled={true}
                />
              </div>
              <div className="form-group col-sm-6 ">
                <label htmlFor="phone">phone</label>
                <InputField
                  type="tel"
                  onChange={onChangeHandler}
                  name={"phone"}
                  value={data?.phone}
                  placeholder={"Enter phone number"}
                  disabled={true}
                />
              </div>
              <div className="form-group col-sm-6 ">
                <label htmlFor="industry">Industry </label>
                <SelectField
                  onChange={onChangeHandler}
                  placeholder={"Select industry"}
                  options={industries}
                  value={data?.industry_id}
                  name={"industry_id"}
                  disabled={true}
                />
              </div>
              <div className="form-group col-sm-6 ">
                <label htmlFor="Address">Address</label>
                <InputField
                  onChange={onChangeHandler}
                  name={"address"}
                  value={data?.address}
                  placeholder={"Enter address"}
                  disabled={true}
                />
              </div>
              <div className="form-group col-sm-6 ">
                <label htmlFor="country">country</label>
                <SelectField
                  onChange={onChangeHandler}
                  placeholder={"Select country"}
                  value={data?.country_id}
                  name={"country_id"}
                  options={countries}
                  disabled={true}
                />
              </div>
              <div className="form-group col-sm-6 ">
                <label htmlFor="state">State </label>
                <SelectField
                  onChange={onChangeHandler}
                  placeholder={"Select state"}
                  value={data?.state_id}
                  name={"state_id"}
                  options={states}
                  disabled={true}
                />
              </div>
              <div className="form-group col-sm-6 ">
                <label htmlFor="lcda">Lcda</label>
                <SelectField
                  onChange={onChangeHandler}
                  placeholder={"Select lcda"}
                  value={data?.lcda_id}
                  name={"lcda_id"}
                  options={lcdas}
                  disabled={true}
                />
              </div>
              <div className="form-group col-sm-6 ">
                <label htmlFor="area">Area </label>
                <SelectField
                  onChange={onChangeHandler}
                  placeholder={"Select area"}
                  value={data?.area_id}
                  name={"area_id"}
                  options={areas}
                  disabled={true}
                />
              </div>
              <div className="form-group col-sm-6 ">
                <label htmlFor="status">Status</label>
                <SelectField
                  onChange={onChangeHandler}
                  placeholder={"Select status"}
                  value={data?.status}
                  name={"status"}
                  options={["ACTIVE", "INACTIVE"]}
                  disabled={true}
                />
              </div>
            </div>
            <div style={styles.button_container}>
              {!loading ? (
                <Button text={"Save"} onClick={submitHandler} type="submit" />
              ) : (
                <RollLoader />
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default BusinessInfoForm;
