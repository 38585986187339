import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { Loading } from "../components/lottie/loader/Loading";
import React from "react";

export const ToastSuccess = (msg) => {
  return NotificationManager.success(msg);
};

export const ToastError = (msg) => {
  return NotificationManager.error(msg);
};
export const Capitalize = (string) => {
  string = string.replace(
    /(^\w|\s\w)(\S*)/g,
    (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase()
  );
  return string;
};
export const confirmAction = (
  outside,
  setOutside,
  actFunction,
  processing,
  name,
  param
) => {
  return confirmAlert({
    childrenElement: () => <div />,
    customUI: ({ onClose }) => (
      <div class="react-confirm-alert">
        <div class="react-confirm-alert-body">
          <h1>Confirm to delete</h1>
          Are you sure to delete '{name}'.
          <div></div>
          <div class="react-confirm-alert-button-group">
            <button
              onClick={async () => {
                setOutside(false);
                actFunction(param);
                onClose();
              }}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    ),
    closeOnEscape: true,
    closeOnClickOutside: true,
    willUnmount: () => {},
    afterClose: () => {},
    onClickOutside: () => {},
    onKeypressEscape: () => {},
    overlayClassName: "overlay-custom-class-name",
  });
};

export const csvToArray = (str, delimiter = ",") => {
  // slice from start of text to the first \n index
  // use split to create an array from string by delimiter
  const headers = str
    .slice(0, str.indexOf("\n"))
    .split(delimiter)
    .map((item) => item.trim());

  // slice from \n index + 1 to the end of the text
  // use split to create an array of each csv value row
  const rows = str.slice(str.indexOf("\n") + 1).split("\n");

  // Map the rows
  // split values from each row into an array
  // use headers.reduce to create an object
  // object properties derived from headers:values
  // the object passed as an element of the array
  const arr = rows
    .map(function (row) {
      const values = row.split(delimiter);
      const el = headers.reduce(function (object, header, index) {
        object[header] = values[index]
          ? values[index].trim().toString()
          : undefined;
        return object;
      }, {});
      return el;
    })
    .filter((item) => item && item[headers[headers.length - 1]] !== undefined);

  // return the array
  return arr;
};

export const validationHandler = (data, fields) => {
  let failed = false;
  let field = "";
  for (let req of fields) {
    if (!data[req] || data[req].toString().trim() === "") {
      failed = true;
      field = req;
    }
  }
  return {
    failed,
    field,
  };
};

export const __flatten = (res) => {
  return (
    res?.data?.data ||
    res?.assigned_vehicles ||
    res?.vehicles ||
    res?.unassigned_vehicles ||
    res?.data ||
    undefined
  );
};

export const queryResponse = (data, query) => {
  return {
    data,
    isLoading: query?.isLoading,
    isFetching: query?.isFetching,
    hasNextPage:
      !!query?.data?.data?.next_page_url || !!query?.data?.next_page_number,
    fetchNextPage: query.fetchNextPage,
    hasPreviousPage:
      !!query?.data?.data?.prev_page_url || !!query?.data?.previous_page_number,
    fetchPrevPage: query.fetchPreviousPage,
    total: query?.data?.data?.total || query?.data?.total || data?.length || 0,
    page: query?.data?.data?.current_page || query?.data?.current_page_number,
    prev_page_num:
      query?.data?.data?.prev_page_url
        ?.match("page=[0-9]")?.[0]
        ?.split("page=")?.[1] ||
      query?.data?.previous_page_number ||
      1,
    next_page_num:
      query?.data?.data?.next_page_url
        ?.match("page=[0-9]")?.[0]
        ?.split("page=")?.[1] ||
      query?.data?.next_page_number ||
      1,
    limit: query?.data?.data?.per_page || 100,
    isFetchNextPage: query.isFetchingNextPage,
  };
};
