import React from "react";
import { Link, NavLink, useHistory, useLocation } from "react-router-dom";

export const Sidebar = () => {
  const history = useHistory();
  const [current, setCurrent] = React.useState("dashboard");
  return (
    <div className="iq-sidebar">
      <div className="iq-sidebar-logo d-flex justify-content-between">
        <Link to="/dashboard">
          <img src="/assets/images/logo.png" className="img-fluid" alt="" />
        </Link>
      </div>
      <div id="sidebar-scrollbar">
        <nav className="iq-sidebar-menu">
          <ul id="iq-sidebar-toggle" className="iq-menu">
            <li>
              <Link to="/dashboard" className="iq-waves-effect">
                <i className="ri-home-8-fill"></i>
                <span>Dashboard</span>
              </Link>
            </li>

            {/* <li>
              <Link to="/realtime" className="iq-waves-effect">
                <i className="ri-briefcase-4-fill"></i>
                <span>Real Time Bikes</span>
              </Link>
            </li> */}

            {/* <li
              className={
                menu.operation.includes(location.pathname) ||
                current === "operation"
                  ? "active"
                  : ""
              }
              onClick={() => setCurrent("operation")}
            >
              <a
                href="#partnrgpssettings1"
                className="iq-waves-effect collapsed"
                data-toggle="collapse"
                aria-expanded="false"
              >
                <i className="ri-mail-open-fill"></i>
                <span>Areas of Operation</span>
                <i className="ri-arrow-right-s-line iq-arrow-right"></i>
              </a>
               <ul
                id="partnrgpssettings1"
                className="iq-submenu collapse"
                data-parent="#iq-sidebar-toggle"
              >
              <li>
                  <Link to="/operation_areas">
                    <i className="ri-inbox-fill"></i>Operation Areas
                  </Link>
                </li> 
               <li>
                  <Link to="/area_list">
                    <i className="ri-inbox-fill"></i>List of Created Areas
                  </Link> 
                </li> 
                 <li><Link to="/create_area"><i className="ri-inbox-fill"></i>Create New Area</Link></li>
                 <li><Link to="/assign_bikes"><i className="ri-inbox-fill"></i>Assign Bikes</Link></li> 
                 <li><Link to="/areas_list"><i className="ri-inbox-fill"></i>List of client's created areas</Link></li>
                 <li><Link to="/create_area"><i className="ri-inbox-fill"></i>Create Client's Area of Operations</Link></li> 
              </ul>
            </li> */}
            <li>
              <Link to="/bike_list" className="iq-waves-effect">
                <i className="ri-motorbike-fill"></i>
                <span>List of Bikes</span>
              </Link>
            </li>

            <li>
              <Link to="/heat_map" className="iq-waves-effect">
                <i className="ri-map-pin-fill"></i>
                <span>Heat Map</span>
              </Link>
            </li>
            {/* <li className={menu.IOT.includes(location.pathname) || current === "IOT" ? "active" : ""}
                        onClick={()=>setCurrent("IOT")}
                     >
                        <a href="#partnrgpssettings2" className="iq-waves-effect collapsed" data-toggle="collapse" aria-expanded="false"><i className="ri-mail-open-fill"></i><span>IOT Data</span><i className="ri-arrow-right-s-line iq-arrow-right"></i></a>
                        <ul id="partnrgpssettings2" className="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                           <li><Link to="/sim_cards"><i className="ri-inbox-fill"></i>List of SIM Cards</Link></li>
                        </ul>
                     </li> */}

            {/* <li>
                            <Link to="/battery_stations" className="iq-waves-effect"><i className="ri-briefcase-4-fill"></i><span>Charging Station Carbinet Monitor</span></Link></li>   */}

            {/* <li
              className={
                menu.teams.includes(location.pathname) || current === "teams"
                  ? "active"
                  : ""
              }
              onClick={() => setCurrent("teams")}
            >
              <a
                href="#partnrgpssettings3"
                className="iq-waves-effect collapsed"
                data-toggle="collapse"
                aria-expanded="false"
              >
                <i className="ri-user-4-fill"></i>
                <span>Teams</span>
                <i className="ri-arrow-right-s-line iq-arrow-right"></i>
              </a>
              <ul
                id="partnrgpssettings3"
                className="iq-submenu collapse"
                data-parent="#iq-sidebar-toggle"
              >
                <li>
                  <Link to="/teams">
                    <i className="ri-user-4-fill"></i>Teams
                  </Link>
                </li>
                <li>
                  <Link to="/add_team">
                    <i className="ri-user-4-fill"></i>Add Member
                  </Link>
                </li>
                <li>
                  <Link to="/roles">
                    <i className="ri-user-4-fill"></i>Roles
                  </Link>
                </li>
              </ul>
            </li> */}
            <li>
              <Link to="/reports" className="iq-waves-effect">
                <i className="ri-notification-4-fill"></i>
                <span>Fleet Geolocation Report</span>
              </Link>
            </li>
            <li>
              <Link to="/geofence" className="iq-waves-effect">
                <i className="ri-markdown-fill"></i>
                <span>Geofence</span>
              </Link>
            </li>
            <li>
              <Link to="/settings" className="iq-waves-effect">
                <i className="ri-settings-4-fill"></i>
                <span>Settings</span>
              </Link>
            </li>
            <li>
              <Link
                to="/"
                className="iq-waves-effect"
                onClick={(e) => {
                  e.preventDefault();
                  localStorage.clear();
                  history.push("/login");
                }}
              >
                <i className=""></i>
                <span>Log out</span>
              </Link>
            </li>
          </ul>
        </nav>
        <div className="p-3"></div>
      </div>
    </div>
  );
};
