export const GET_CONTACT = "get_contact";
export const GET_INDUSTRIES = "get_industries";
export const GET_COUNTRIES = "get_countries";
export const GET_COUNTRY_STATES = "get_country_states";
export const GET_STATE_LCDA = "get_state_lcda";
export const GET_LCDA_AREAS = "get_lcda_areas";
export const GET_ASSIGNED_BIKES = "get_assigned_bikes";
export const GET_BIKE_REPORTS = "get_bike_reports";
export const GET_LOGS = "get_logs";
export const GET_FLEET_METRIC = "fleet_metric";
export const GEOFENCES = "geofences";
