import AppColors from "../../utils/AppColors";

const styles = {
  icon: {
    background: AppColors.red,
    border: "none",
    borderRadius: "50rem",
    width: "2rem",
    height: "2rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  disabled: {
    background: AppColors.gray,
    border: "none",
    borderRadius: "50rem",
    width: "2rem",
    height: "2rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

export default styles;
