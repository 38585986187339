import { useMutation, useQueryClient } from "react-query";
import { APIFunctions } from "./api";
import { GEOFENCES, GET_ASSIGNED_BIKES } from "./query_keys";
import { ToastError, ToastSuccess } from "./helpers";
import { useMemo } from "react";

export const usePostLockBike = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(APIFunctions.lock_vehicle, {
    onSuccess: (resp) => {
      queryClient.invalidateQueries(GET_ASSIGNED_BIKES);
      if (onSuccess) return onSuccess(resp);
      ToastSuccess("Bike has been locked");
    },
    onError: (err) => {
      queryClient.invalidateQueries(GET_ASSIGNED_BIKES);
      if (onError) return onError(err);
      ToastError(err?.msg);
    },
  });
  return useMemo(() => {
    return {
      isLoading: mutation.isLoading,
      mutate: mutation.mutate,
    };
  }, [mutation.isLoading]);
};

export const usePostUnLockBike = (onSuccess) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(APIFunctions.unlock_vehicle, {
    onSuccess: () => {
      queryClient.invalidateQueries(GET_ASSIGNED_BIKES);
      if (onSuccess) return onSuccess();
      ToastSuccess("Bike has been unlocked");
    },
    onError: (err) => {
      ToastError(err?.msg);
    },
  });
  return useMemo(() => {
    return {
      isLoading: mutation.isLoading,
      mutate: mutation.mutate,
    };
  }, [mutation.isLoading]);
};

export const usePostUnLockBattery = (onSuccess) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(APIFunctions.unlock_battery, {
    onSuccess: () => {
      queryClient.invalidateQueries(GET_ASSIGNED_BIKES);
      if (onSuccess) return onSuccess();
      ToastSuccess("Battery has been unlocked");
    },
    onError: (err) => {
      ToastError(err?.msg);
    },
  });
  return useMemo(() => {
    return {
      isLoading: mutation.isLoading,
      mutate: mutation.mutate,
    };
  }, [mutation.isLoading]);
};

export const usePostLockBattery = (onSuccess) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(APIFunctions.lock_battery, {
    onSuccess: () => {
      queryClient.invalidateQueries(GET_ASSIGNED_BIKES);
      if (onSuccess) return onSuccess();
      ToastSuccess("Battery has been locked");
    },
    onError: (err) => {
      ToastError(err?.msg);
    },
  });
  return useMemo(() => {
    return {
      isLoading: mutation.isLoading,
      mutate: mutation.mutate,
    };
  }, [mutation.isLoading]);
};

export const useSetBikeAlarm = (onSuccess) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(APIFunctions.set_alarm, {
    onSuccess: () => {
      queryClient.invalidateQueries(GET_ASSIGNED_BIKES);
      if (onSuccess) return onSuccess();
      ToastSuccess("Bike alarm has been set");
    },
    onError: (err) => {
      ToastError(err?.msg);
    },
  });
  return useMemo(() => {
    return {
      isLoading: mutation.isLoading,
      mutate: mutation.mutate,
    };
  }, [mutation.isLoading]);
};

export const useRemoveBikeAlarm = (onSuccess) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(APIFunctions.remove_alarm, {
    onSuccess: () => {
      queryClient.invalidateQueries(GET_ASSIGNED_BIKES);
      if (onSuccess) return onSuccess();
      ToastSuccess("Bike alarm has been unset");
    },
    onError: (err) => {
      ToastError(err?.msg);
    },
  });
  return useMemo(() => {
    return {
      isLoading: mutation.isLoading,
      mutate: mutation.mutate,
    };
  }, [mutation.isLoading]);
};

export const useCreateGeofence = (onSuccess) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(APIFunctions.create_geofence, {
    onSuccess: () => {
      queryClient.invalidateQueries(GEOFENCES);
      if (onSuccess) return onSuccess();
      ToastSuccess("Record has been saved");
    },
    onError: (err) => {
      ToastError(err?.msg);
    },
  });
  return useMemo(() => {
    return {
      isLoading: mutation.isLoading,
      mutate: mutation.mutate,
    };
  }, [mutation.isLoading]);
};

export const useUpdateGeofence = (onSuccess) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(APIFunctions.update_geofence, {
    onSuccess: () => {
      queryClient.invalidateQueries(GEOFENCES);
      if (onSuccess) return onSuccess();
      ToastSuccess("Record has been saved");
    },
    onError: (err) => {
      ToastError(err?.msg);
    },
  });
  return useMemo(() => {
    return {
      isLoading: mutation.isLoading,
      mutate: mutation.mutate,
    };
  }, [mutation.isLoading]);
};
